import React from "react"

import Layout from "../components/layout-static"
import Seo from "../components/seo"


const Page = () => {
  const metadata = {
    title: "Hartford Teenager Dies From Crash After Police Chase",
    heroH1: "Hartford Teenager Dies From Crash After Police Chase",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <p className="mb-8">
              Xavier Myers age 18 was pronounced dead Monday morning following a
              crash resulting from a police chase from Hartford to Bloomfield.
              Police believed there was a gun in the car and attempted to stop
              the car driven by Myers. Myers failed to stop which initiated the
              chase. A sixteen year old passenger with Myers suffered minor
              injuries.{" "}
            </p>
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default Page
